import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { PostImage } from './CardStyledComponents'
import Pin from './Pin'

const PostImageContainer = ({ slug, featuredImage, title }) => {
  return (
    <PostImage>
      <Pin title={title} slug={slug} url={featuredImage.file.url} />
      <Link to={`/${slug}/`}>
        <Img fluid={featuredImage.fluid} alt={featuredImage.title} />
      </Link>
    </PostImage>
  )
}

export default PostImageContainer
