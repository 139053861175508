import React from 'react'
import { Link } from 'gatsby'
import {
  PostContent,
  Category,
  Title,
  Date,
  Excerpt,
} from './CardStyledComponents'

const PostContentContainer = ({
  slug,
  title,
  category,
  publishedDate,
  content,
}) => {
  return (
    <PostContent>
      <div className="post-content-container">
        <Link to={`/category/${category.slug}`}>
          <Category>{category.categoryName}</Category>
        </Link>
        <Link to={`/${slug}/`}>
          <Title>{title}</Title>
        </Link>
        <Date>{publishedDate}</Date>
        <Excerpt
          dangerouslySetInnerHTML={{
            __html: content.childMarkdownRemark.excerpt,
          }}
        />
      </div>
    </PostContent>
  )
}

export default PostContentContainer
