import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Button } from './CardStyledComponents'

const FavoriteWrapper = styled.div`
  display: grid;
  align-content: center;
  margin-bottom: 4rem;
  padding: 100px 0;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  background: ${props => props.theme.colors.base};
  color: ${props => props.theme.colors.secondary};
  h3 {
    font-family: 'Signature', cursive;
    font-size: 4rem;
    text-align: center;
  }
  .favorite-button {
    margin: 25px auto 0 auto;
  }
`
const FavoriteContainer = styled.div`
  margin: 0 10%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  a {
    height: 100%;
  }
  img {
    border-radius: 5px;
    height: 100%;
  }
`
const Underline = styled.h1`
  font-family: 'Signature Liga', cursive;
  font-size: 4rem;
  text-align: center;
  padding-bottom: 25px;
  color: ${props => props.theme.colors.secondary};
`

const BeautyProductBanner = () => (
  <StaticQuery
    query={graphql`
      {
        allContentfulFavorite(
          limit: 6
          filter: {
            currentFavorite: { eq: true }
            type: { eq: "beauty-product" }
          }
        ) {
          edges {
            node {
              slug
              currentFavorite
              favoritePhotos {
                title
                fluid(maxWidth: 400, cropFocus: FACES, resizingBehavior: FILL) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              favoriteLink {
                itemName
                imageUrl
                itemUrl
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        {data.allContentfulFavorite && (
          <FavoriteWrapper>
            <h3>Paige's Favorite Beauty Products</h3>
            <Underline>4</Underline>
            <FavoriteContainer>
              {data.allContentfulFavorite.edges.map(({ node: favorite }) => (
                <Link
                  to={`/beauty-products/${favorite.slug}`}
                  key={favorite.slug}
                >
                  <Img
                    fluid={favorite.favoritePhotos[0].fluid}
                    style={{ height: '100%' }}
                    alt={favorite.favoritePhotos[0].title}
                  />
                </Link>
              ))}
            </FavoriteContainer>
            <Link to="/beauty-products" className="favorite-button">
              <Button type="button">See All Beauty Products</Button>
            </Link>
          </FavoriteWrapper>
        )}
      </div>
    )}
  />
)

export default BeautyProductBanner
