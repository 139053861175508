import styled from 'styled-components'
import { PinLink } from './Pin'

const Post = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 0 4em 0;
  width: 100%;
  transition: background 0.2s;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  background: ${props => props.theme.colors.base};
  .post-button {
    display: grid;
    justify-content: center;
  }
  a {
    color: ${props => props.theme.colors.secondary};
    text-decoration: none;
  }
`
const PostReverse = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 0 4em 0;
  width: 100%;
  transition: background 0.2s;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: ${props => props.theme.colors.base};
  .post-button {
    display: grid;
    justify-content: center;
  }
  a {
    color: ${props => props.theme.colors.secondary};
    text-decoration: none;
  }
`
const PostImage = styled.div`
  width: 50%;
  height: auto;
  padding: 0;
  margin: 0;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    width: 100%;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    &:hover ${PinLink} {
      visibility: visible;
    }
  }
`
const PostContent = styled.div`
  background: ${props => props.theme.colors.base};
  width: 50%;
  height: auto;
  padding: 0;
  margin: 0;
  .post-content-container {
    padding-top: 20%;
  }
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    width: 100%;
    .post-content-container {
      padding-top: 5%;
    }
  }
`
const Category = styled.h4`
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.secondary};
  font-weight: 300;
  letter-spacing: 2px;
  &:hover {
    font-weight: bold;
  }
`
const Title = styled.h2`
  font-family: 'Signature', cursive;
  font-size: 50px;
  margin: 1rem 1rem 1.5rem 1rem;
  text-align: center;
  font-weight: 300;
  &:hover {
    font-weight: bold;
    color: ${props => props.theme.colors.tertiary};
  }
`
const Date = styled.h3`
  margin: 0 1rem 1.5rem 1rem;
  color: ${props => props.theme.colors.secondary};
  text-align: center;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2px;
`
const Excerpt = styled.p`
  margin: 0 2rem 1.5rem 2rem;
  font-size: 1rem;
  line-height: 30px;
  font-family: 'Halant', serif;
  font-weight: 300;
`

const Button = styled.button`
  background: transparent;
  border: 1px solid ${props => props.theme.colors.secondary};
  width: 200px;
  height: 50px;
  border-radius: 5px;
  text-align: center;
  color: ${props => props.theme.colors.secondary};
  margin: 0 auto;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300px;
  font-size: 0.8rem;
  &:hover {
    background: ${props => props.theme.colors.secondary};
    color: white;
  }
`

export {
  Post,
  PostReverse,
  PostContent,
  PostImage,
  Category,
  Title,
  Date,
  Excerpt,
  Button,
}
