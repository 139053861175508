import React from 'react'
import { PostReverse } from './CardStyledComponents'

import PostContentContainer from './PostContentContainer'
import PostImageContainer from './PostImageContainer'

const Card = ({
  slug,
  featuredImage,
  title,
  category,
  publishedDate,
  content,
}) => {
  return (
    <PostReverse>
      <PostImageContainer
        slug={slug}
        featuredImage={featuredImage}
        category={category}
        title={title}
      />
      <PostContentContainer
        slug={slug}
        category={category}
        title={title}
        publishedDate={publishedDate}
        content={content}
      />
    </PostReverse>
  )
}

export default Card
